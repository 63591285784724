<template>
  <div class="page site-wrap">
    <header class="page__header">
      <router-link to="/">
        <h1 class="page__logo">
          <span class="visuallyhidden">vigilex monitoringregulacyjny.pl</span>
          <base-logo-vertical class="mobile-tablet" />
          <base-logo-horizontal class="desktop" />
        </h1>
      </router-link>
      <navigation />
    </header>

    <main class="page__content">
      <slot />
    </main>

    <page-footer class="page__footer" />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation/Navigation';
import PageFooter from '@/components/PageFooter/PageFooter';

export default {
  components: {
    Navigation,
    PageFooter,
  },
};
</script>

<style lang="scss" scoped>
@import './Page';
</style>
