/* eslint-disable */
import {
  OTHER_ACT_MAP,
  OTHER_ACT_NOT_MONITORED,
  OTHER_ACT_NOT_SEARCHABLE,
} from '@/util/otherActs';
import { UE_MAP, UE_NOT_MONITORED, UE_NOT_SEARCHABLE } from '@/util/ue';

const specialRoutes = (
  name,
  noneStoreGetter,
  routes,
  MAP,
  NOT_MONITORED,
  NOT_SEARCHABLE,
  mobileShowChildren
) => {
  if (routes && routes.length > 0) {
    return {
      main: [
        {
          to: { name: MAP[routes[0]] },
          name,
          mobileShowChildren,
          private: true,
          children: routes.map(act => ({
            to: { name: MAP[act] },
            name: act,
            private: true,
          })),
        },
      ],
      monitoring: routes
        .filter(act => !NOT_MONITORED.includes(act))
        .map(act => ({
          to: {
            name: `my-monitoring-${MAP[act]}`,
          },
          name: act,
          private: true,
        })),
      search: routes
        .filter(act => !NOT_SEARCHABLE.includes(act))
        .map(act => ({
          name: act,
          relation: MAP[act],
        })),
    };
  }
  return {
    main: [
      {
        name,
        private: true,
        modalSecondaryCondition: `user/${noneStoreGetter}`,
      },
    ],
    monitoring: [],
    search: [],
  };
};

export const mainMenu = (otherActs, ue) => {
  const otherActRoutes = specialRoutes(
    'inne akty',
    'noOtherActs',
    otherActs,
    OTHER_ACT_MAP,
    OTHER_ACT_NOT_MONITORED,
    OTHER_ACT_NOT_SEARCHABLE,
    true
  );
  const ueRoutes = specialRoutes(
    'UE',
    'noUe',
    ue,
    UE_MAP,
    UE_NOT_MONITORED,
    UE_NOT_SEARCHABLE,
    false
  );
  // let ueMonitoring = [];
  // if (ueRoutes.monitoring.length) {
  //   ueMonitoring = [
  //     {
  //       to: ueRoutes.monitoring[0].to,
  //       name: 'UE',
  //       private: true,
  //     },
  //   ];
  // }
  let ueSearch = [];
  if (ueRoutes.search.length) {
    ueSearch = [
      {
        ...ueRoutes.search[0],
        name: 'UE',
      },
    ];
  }

  return [
    // {
    //   to: { name: 'legislation' },
    //   name: 'ustawy',
    // },
    // {
    //   to: { name: 'regulations' },
    //   name: 'rozporządzenia',
    // },
    // ...otherActRoutes.main,
    // ...ueRoutes.main,
    {
      to: { name: 'news' },
      name: 'aktualności',
    },
    {
      to: { name: 'my-monitoring' },
      name: 'mój monitoring',
      private: true,
      // children: [
      //   {
      //     to: { name: 'my-monitoring-legislation' },
      //     name: 'ustawy',
      //     private: true,
      //   },
      //   {
      //     to: { name: 'my-monitoring-regulations' },
      //     name: 'rozporządzenia',
      //     private: true,
      //   },
      //   ...otherActRoutes.monitoring,
      //   ...ueMonitoring,
      // ],
    },
    {
      name: 'szukaj',
      search: true,
      // children: [
      //   {
      //     name: 'ustawy',
      //     relation: 'legislation',
      //   },
      //   {
      //     name: 'rozporządzenia',
      //     relation: 'regulations',
      //   },
      //   ...otherActRoutes.search,
      //   ...ueSearch,
      // ],
    },
    {
      to: { name: 'account' },
      name: 'moje konto',
      account: false,
      private: true,
    },
  ];
};
