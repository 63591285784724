<template>
  <section class="section-six mb-32 mb-desktop-64" id="about-us">
    <h2 class="heading color-primary section-six__heading mb-16">
      Kim jesteśmy <span class="color-primary-darken">i&nbsp;co&nbsp;robimy?</span>
    </h2>

    <p>
      <strong>
        Serwis vigilex to odpowiedź na potrzebę szybkiego dostępu do aktualnych
        informacji o&nbsp;projektowanych zmianach prawnych.
      </strong>
    </p>

    <p class="mb-32">
      Rozwijamy vigilex, ponieważ wielokrotnie odczuwaliśmy brak podobnego
      narzędzia. Mamy świadomość, że monitoring legislacyjny jest nudny i&nbsp;żmudny,
      a wiele związanych z&nbsp;nim czynności da się zautomatyzować.
      Wiemy też, jak to zrobić.
    </p>

    <div>
      <div class="section-six__person mb-16 mb-tablet-24">
        <div class="section-six__person-info mb-4 mb-tablet-0">
          <img
            class="section-six__person-photo mb-4"
            src="../assets/szymon.jpg"
            alt=""
            width="200"
            height="200"
          />
          <div>
            <p>
              <strong>Szymon Łajszczak</strong>
              <br />założyciel
            </p>
            <p class="section-six__person-title">
              Doktor nauk prawnych i&nbsp;radca prawny
            </p>
          </div>
        </div>
        <base-more disableAbove="tablet" centerToggle>
          <p>
            Pomysł na vigilex kiełkował przez lata spędzone przez Szymona na
            doradztwie dla firm z&nbsp;branż regulowanych. Jego ważnym elementem
            był zawsze monitoring legislacyjny. Żeby uczynić go bardziej
            przystępnym, Szymon połączył dwie pasje: do prawa
            i&nbsp;programowania. Dzięki temu vigilex łączy szybkość
            i&nbsp;automatyzację z&nbsp;głęboką znajomością procesu
            legislacyjnego i&nbsp;zasad techniki prawodawczej.
          </p>
          <p>
            Szymon odpowiada za kontakty z&nbsp;kancelariami i&nbsp;działami
            prawnymi, gromadząc informacje, dzięki którym vigilex może być
            dokładnie taki, jakiego oczekują inni prawnicy. Ci którzy go
            potrzebują lub już korzystają z&nbsp;niego na co dzień.
          </p>
        </base-more>
      </div>

      <div class="section-six__person mb-16 mb-tablet-24">
        <div class="section-six__person-info mb-4 mb-tablet-0">
          <img
            class="section-six__person-photo mb-4"
            src="../assets/piotr.jpg"
            alt=""
            width="200"
            height="200"
          />
          <p>
            <strong>Piotr Kaznowski</strong>
            <br />programista
          </p>
        </div>
        <base-more disableAbove="tablet" centerToggle>
          <p>
            Kolejny programujący absolwent studiów humanistycznych
            w&nbsp;zespole – historyk filozofii i&nbsp;publicysta,
            a&nbsp;jednocześnie niezwykle skrupulatny deweloper, którego
            cierpliwość i&nbsp;systematyczność gwarantują, że aplikacja będzie
            działać zgodnie z&nbsp;zamierzeniem.
          </p>
          <p>
            Odpowiada za wdrożenie i&nbsp;testy nowych funkcjonalności
            i&nbsp;adaptację najlepszych rozwiązań technicznych dla wygody
            użytkowników vigilex.
          </p>
        </base-more>
      </div>

      <div class="section-six__person mb-16 mb-tablet-24">
        <div class="section-six__person-info mb-4 mb-tablet-0">
          <img
            class="section-six__person-photo mb-4"
            src="../assets/filip.jpg"
            alt=""
            width="200"
            height="200"
          />
          <p>
            <strong>Filip Łajszczak</strong>
            <br />konsultant techniczny
          </p>
        </div>
        <base-more disableAbove="tablet" centerToggle>
          <p>
            Programować zaczął jeszcze w&nbsp;szkole podstawowej i&nbsp;choć
            ostatecznie informatyka przegrała z&nbsp;politologią, po latach
            wrócił do kodu i&nbsp;dziś jest zawodowym deweloperem.
          </p>
          <p>
            Filip odpowiada za wybór i&nbsp;wdrożenie kluczowych technologii
            stojących za vigilex. Aplikację wzbogaca również dzięki
            doświadczeniu zdobytemu podczas pracy w&nbsp;Kancelarii Sejmu
            i&nbsp;Parlamencie Europejskim.
          </p>
        </base-more>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isCollapsed: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.more {
  animation: fadeInBottom 0.5s $easeOutCubic;
}
.section-six {
  max-width: 54.5rem;
  margin-left: auto;
  margin-right: auto;
}
.section-six__person {
  @include mq('tablet') {
    display: flex;

    &:nth-child(even) {
      flex-direction: row-reverse;
      text-align: right;

      .section-six__person-info {
        text-align: left;
        margin-right: 0;
        margin-left: 4rem;
      }
    }
  }
}
.section-six__person-info {
  display: flex;
  align-items: center;
  font-size: 1.28rem;

  @include mq('tablet') {
    display: block;
    min-width: 14rem;
    margin-right: 4rem;
    text-align: right;
  }
}
.section-six__person-title {
  font-size: 1rem;

  @include mq('phone') {
    font-size: inherit;
  }
}
.section-six__person-photo {
  width: 9rem;
  height: auto;
  margin-right: 1.5rem;

  @include mq('tablet') {
    width: 12rem;
    margin-right: 0;
  }
}
</style>
