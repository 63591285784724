<template>
  <div class="slider-container">
    <div class="clients-title">
      ZAUFALI NAM
    </div>
    <div class="slider">
      <div class="gradient-overlay left"></div>
      <div class="group">
        <div class="slide" v-for="(logo, index) in [...logos]" :key="index">
          <img :src="logo" alt="" oncontextmenu="return false;" />
        </div>
      </div>
      <div aria-hidden class="group">
        <div class="slide" v-for="(logo, index) in [...logos]" :key="index">
          <img
            :src="logo"
            alt=""
            draggable="false"
            onmousedown="return false"
            style="user-drag: none"
            oncontextmenu="return false;"
          />
        </div>
      </div>
      <div class="gradient-overlay right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logos: [],
    };
  },
  created() {
    this.loadLogos();
  },
  mounted() {
    this.calculateSlideWidths();
  },
  methods: {
    loadLogos() {
      const context = require.context(
        '@/assets/clients',
        false,
        /\.(png|jpe?g|svg)$/
      );
      this.logos = context.keys().map(file => context(file));
    },
    calculateSlideWidths() {
      this.$nextTick(() => {
        const slides = document.querySelectorAll('.slide');
        slides.forEach(slide => {
          const img = slide.querySelector('img');
          if (img && img.complete) {
            this.setSlideWidth(slide, img);
          } else if (img) {
            img.onload = () => this.setSlideWidth(slide, img);
          }
        });
      });
    },
    setSlideWidth(slide, img) {
      const style = window.getComputedStyle(slide);
      const paddingLeft = parseFloat(style.paddingLeft) || 0;
      const paddingRight = parseFloat(style.paddingRight) || 0;
      const imgWidth = img.offsetWidth;
      const totalWidth = imgWidth + paddingLeft + paddingRight;
      slide.style.width = `${totalWidth}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
$duration: 30s; // Here you can set the duration of the animation (actual SPEED)

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.clients-title {
  font-size: 12px;
  text-align: left;
  color: rgb(155, 155, 155);
  margin-bottom: 20px;

  @media (max-width: 768px) {
    text-align: center;
    margin-top: 50px;
  }
}
.slider-container {
  position: relative;
  text-align: center;
  z-index: 0;
  .slider {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    max-width: 100%;
    flex: 0 0 100%;
    display: flex;

    &:hover {
      .group {
        animation-play-state: paused;
      }
    }

    .gradient-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 20%;
      pointer-events: none;
      z-index: 2;
    }

    .gradient-overlay.left {
      left: 0;
      background: linear-gradient(to right, white 0%, transparent 100%);
    }

    .gradient-overlay.right {
      right: 0;
      background: linear-gradient(to left, white 0%, transparent 100%);
    }

    .group {
      display: flex;
      gap: 10px;
      padding-right: 20px;
      will-change: transform;
      animation: scrolling $duration linear infinite;

      .slide {
        padding: 0 20px;
        height: 72px;
        filter: grayscale(100%);
        transition: all 0.3s ease;
        opacity: 0.3;
        display: flex;

        &:hover {
          filter: grayscale(0%);
          opacity: 1;
        }

        img {
          max-width: unset !important;
          width: auto;
        }
      }
    }
  }
}
</style>
