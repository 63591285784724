<template>
  <section class="mb-32 mb-desktop-64 section-seven" id="contact">
    <div class="section-seven__headings mb-12">
      <h2 class="heading color-primary-darken">
        Potrzebujesz czegoś?
      </h2>
      <p class="heading color-primary">Odezwij się do nas!</p>
    </div>

    <div class="row">
      <ul
        class="section-seven__list list list--arrow-inner col-12 col-desktop-7 col-tablet-7 mb-8 mb-tablet-0"
      >
        <li>chcesz przekazać swoje uwagi na temat aplikacji</li>
        <li>chciałbyś dowiedzieć się więcej o vigilex</li>
        <li>chciałbyś rozszerzyć monitoring na praktykę regulatorów</li>
        <li>
          wolałbyś zamówić gotowy raport dotyczący wybranego obszaru prac
          legislacyjnych
        </li>
      </ul>

      <div class="section-seven__buttons col-12 col-desktop-5 col-tablet-5">
        <base-button
          tag="a"
          href="tel:+48695975360"
          theme="primary"
          class="mb-4"
          full
          >Zadzwoń 695 975 360</base-button
        >

        <base-button @click="modalOpen = true" theme="secondary" full
          >Wyślij wiadomość</base-button
        >
        <contact-modal
          :open="modalOpen"
          @close="modalOpen = false"
          @forceOpen="modalOpen = true"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ContactModal from '../../../components/ContactModal';

export default {
  components: { ContactModal },
  data() {
    return {
      modalOpen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.section-seven {
  margin-left: -1rem;
  margin-right: -1rem;
  border-radius: 1.4rem;
  padding: 2.5rem 1.5rem;
  background-color: $gray-5;

  @include mq('phone') {
    margin-left: 0;
    margin-right: 0;
  }
  @include mq('tablet') {
    padding: 4.2rem 3.5rem;

    .heading {
      display: inline-block;
    }
  }
  @include mq('desktop') {
    padding: 8rem 7rem 7rem;
  }

  &__headings {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @include mq('tablet') {
      flex-direction: row;
    }

    h2,
    p {
      font-size: 2.14rem;

      @include mq('desktop') {
        font-size: 2.85rem;
      }
    }
  }

  &__list {
    @include mq('tablet') {
      padding: 0 2.8rem;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq('tablet') {
      padding: 0 2.8rem;
    }
  }
}
</style>
