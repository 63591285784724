<template>
  <section class="mb-32 mb-desktop-64 section-five">
    <h2 class="heading mb-8">Załóż konto już teraz!</h2>

    <div class="section-five__content row">
      <div class="col-12 col-desktop-8 col-tablet-8">
        <ul class="list list--outside-arrow list--white-arrow">
          <li>
            otrzymasz codzienną porcję informacji o&nbsp;projektach ustaw
            i&nbsp;rozporządzeń z&nbsp;Twojej dziedziny
          </li>
          <li>
            będziesz wiedzieć, jakie istotne zmiany dotkną Twoją branżę,
            organizację lub klienta
          </li>
          <li>
            zyskasz czas na dostosowanie się do zmieniających się przepisów
          </li>
        </ul>
      </div>

      <form
        @submit.prevent="signUp"
        class="col-12 col-desktop-4 col-tablet-4 mb-8 mb-tablet-0"
      >
        <p class="form-info">Podaj adres e-mail:</p>

        <base-input
          theme="light"
          name="username"
          required
          type="email"
          v-model="email"
          class="mb-8 mb-tablet-12"
        >
          adres e-mail
        </base-input>
        <base-button type="submit" theme="secondary mb-4" uppercase full
          >załóż konto</base-button
        >
      </form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      email: '',
    };
  },
  methods: {
    signUp() {
      this.$router.push({ path: '/signup', query: { email: this.email } });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-five {
  display: block;
  color: #fff;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 2.5rem 3.2rem;
  border-radius: 1.42rem;
  background: $gradient-secondary;

  @include mq('phone') {
    margin-left: 0;
    margin-right: 0;
  }
  @include mq('tablet') {
    padding: 3.2rem 3.5rem;
    background: $gradient-secondary-diagonal;
  }
  @include mq('desktop') {
    padding: 6.4rem 7rem;
  }

  .heading,
  .form-info {
    text-align: center;

    @include mq('tablet') {
      text-align: left;
    }
  }
  .list {
    @include mq('tablet') {
      padding-right: 2.5rem;
    }
  }

  &__content {
    flex-direction: column-reverse;

    @include mq('tablet') {
      flex-direction: row;
    }
  }
}
</style>
