<template>
  <div class="section-one-wrapper">
    <section class="row row--vcenter section-one">
      <div class="col-12 col-desktop-7 col-tablet-7 left-side">
        <div ref="phoneDesktopSibling" class="desktop-sibling">
          <h2 class="heading color-primary heading--bigger">
            Nie daj się zaskoczyć zmianom prawa.
          </h2>
          <p
            class="heading color-primary-darken heading--bigger mb-4 mb-tablet-8"
          >
            Dowiedz się o&nbsp;nowych przepisach zanim zaczną Cię obowiązywać.
          </p>

          <ul class="list list--with-dots mb-12">
            <li class="color-primary-darken">
              bieżący monitoring zmian legislacyjnych
            </li>
            <li class="color-primary-darken">
              selekcja projektów według Twoich potrzeb
            </li>
          </ul>

          <div class="section-one__buttons mb-8">
            <base-button
              tag="router-link"
              to="/signup"
              theme="secondary"
              uppercase
              class="mb-2"
              >załóż konto</base-button
            >
            <base-button
              tag="router-link"
              :to="
                $store.getters['user/isLoggedIn'] ? { name: 'news' } : '/login'
              "
              class="mobile"
              theme="gray-5"
              uppercase
            >
              <template v-if="$store.getters['user/isLoggedIn']">
                do serwisu
              </template>
              <template v-else>
                zaloguj się
              </template>
            </base-button>
          </div>
        </div>
        <home-clients />
      </div>

      <div
        class="section-one__image col-desktop-5 col-tablet-5 tablet-desktop"
        ref="phoneDesktopParent"
      >
        <phone-component
          v-if="!mobile"
          class="phone__desktop"
          ref="phoneDesktop"
        />
        <!-- <img
          src="@/assets/screenshots/phone.png"
          srcset="
            @/assets/screenshots/phone.png,
            @/assets/screenshots/phone-2x.png 2x
          "
          class="section-one__image-img"
          alt=""
          width="415"
          height="672"
        /> -->
      </div>
    </section>
    <a
      href="#section-two"
      class="section-one__scroll-down text-center color-primary mb-16 mb-tablet-32"
    >
      Dowiedz się więcej
      <img
        class="section-one__scroll-down-icon"
        src="../assets/icon-arrow-down-green.svg"
        alt=""
      />
    </a>

    <!-- <a
      href="#section-two"
      class="section-one__scroll-down text-center color-primary mb-16 mb-tablet-32"
    >
      Dowiedz się więcej
      <img
        class="section-one__scroll-down-icon"
        src="../assets/icon-arrow-down-green.svg"
        alt=""
      />
    </a> -->

    <div class="section-one__image-mobile mobile">
      <phone-component
        v-if="mobile"
        class="phone__mobile section-one__image-mobile-img"
        ref="phoneMobile"
      />
      <!-- <img
        src="@/assets/screenshots/phone.png"
        srcset="
          @/assets/screenshots/phone.png,
          @/assets/screenshots/phone-2x.png 2x
        "
        class="section-one__image-mobile-img"
        alt=""
        width="415"
        height="672"
      /> -->
    </div>
  </div>
</template>

<script>
import PhoneComponent from './PhoneComponent.vue';
import HomeClients from './HomeClients';
import { debounce } from '@/util/debounce';
export default {
  components: { PhoneComponent, HomeClients },
  data() {
    return {
      modalOpen: false,
      mobile: false,
    };
  },
  methods: {
    onResize: debounce(function() {
      if (window.innerWidth < 768) {
        this.mobile = true;
      } else {
        this.mobile = false;
        this.$nextTick(() => {
          const element = this.$refs.phoneDesktop.$el;
          if (element) {
            const sibling = this.$refs.phoneDesktopSibling;
            let factor = window.innerWidth / 6;
            if (window.innerWidth > 1600) {
              factor = 300;
            } else if (window.innerWidth > 1250) {
              factor = 270;
            } else if (window.innerWidth > 1100) {
              factor = 100;
            } else if (window.innerWidth > 1025) {
              factor = 60;
            } else if (window.innerWidth > 900) {
              factor = 120;
            } else if (window.innerWidth > 800) {
              factor = 100;
            } else {
              factor = 0;
            }

            const ratio = (sibling.clientHeight + factor) / 758;
            element.style.transform = `scale(${ratio})`;
          }
        });
      }
    }, 50),
  },
  created() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.section-one {
  font-size: 1.14rem;
  padding-top: 2rem;
  // min-height: calc(100vh - 150px);

  .tablet-desktop {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .phone__desktop {
    transition: all 0.4s ease;
    @include mq('tablet') {
      margin-left: -50px;
      transform-origin: center;
    }
    @include mq('desktop') {
      margin-left: -0px;
      transform-origin: center;

      // transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }

  @media (min-height: 700px) and (min-width: 768px) {
    padding-top: 0;
  }
  @include mq('tablet') {
    min-height: 0;
    //height: calc(100vh - 160px);
    margin-right: -2.5rem;
    .heading {
      font-size: 36px !important;
    }
  }
  @include mq('desktop') {
    height: calc(100vh - 180px);
    font-size: 1.85rem;
    margin-right: -5.5rem;
    .heading {
      font-size: 45px !important;
    }

    li {
      margin-bottom: 0;
    }
  }

  .heading {
    font-size: calc(16px + 1.6vh);

    @media (min-height: 700px) and (min-width: 768px) {
      font-size: 2.85rem;
    }
    @include mq('tablet') {
      font-size: 36px;
    }
    @include mq('desktop') {
      font-size: 45px;
    }
  }

  &__image {
    height: 100%;

    &-img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    margin-top: 1.4rem;
    flex-direction: column;
    align-items: center;

    @include mq('tablet') {
      align-items: start;
    }

    .base-button {
      width: 256px;
    }

    .base-button:last-of-type {
      color: $gray-2 !important;
    }
  }

  &__scroll-down {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 20px;

    @include mq('tablet') {
      font-size: 1.28rem;
      margin-top: 0;
    }

    &-icon {
      display: block;
      margin: auto;
      margin-top: 1rem;
    }
  }
}

.section-one__image-mobile {
  margin: auto;
  margin-bottom: -465px;
  max-width: 347px;

  .phone__mobile {
    top: -140px;
    margin-left: 20px;
  }

  &-img {
    width: 100%;
    height: auto;
    position: relative;
    left: -9.5%;
  }
}
</style>
