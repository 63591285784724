<template>
  <div>
    <h2 class="login__form-title mb-12 mb-tablet-8">
      Zaloguj się
    </h2>

    <form @submit.prevent="submit">
      <base-input
        ref="username"
        v-model="username"
        :error="error"
        name="username"
        required
        :autofocus="true"
        theme="light-blue"
        class="mb-8 mb-tablet-12"
        >adres e-mail</base-input
      >
      <base-input
        v-model="password"
        :error="error"
        name="password"
        type="password"
        required
        theme="light-blue"
        class="mb-4"
        >hasło</base-input
      >

      <div class="login__extra mb-12 mb-desktop-8">
        <base-checkbox
          v-model="remember"
          name="remember"
          theme="light"
          class="login__remember mb-4 mb-desktop-0"
          simple
          >Zapamiętaj mnie</base-checkbox
        >
        <router-link to="/password/reset" class="login__extra-link">
          Nie pamiętam hasła
        </router-link>
      </div>

      <base-button
        type="submit"
        theme="secondary"
        class=" mb-4"
        :loading="loading"
        full
        plain
      >
        Dalej
      </base-button>
    </form>

    <div class="login__links">
      <router-link to="/signup" class="login__links-item">
        Załóż konto
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      username: '',
      password: '',
      remember: false,
      error: false,
    };
  },
  watch: {
    username() {
      this.removeError();
    },
    password() {
      this.removeError();
    },
  },
  computed: {
    ...mapState({
      loggedIn: state => state.user.loggedIn,
    }),
  },
  created() {
    if (this.loggedIn) {
      this.redirect();
    }
  },
  methods: {
    removeError() {
      this.error = false;
    },
    async submit() {
      this.error = false;
      this.loading = true;
      try {
        await this.$store.dispatch('user/login', {
          username: this.username,
          password: this.password,
          remember: this.remember,
        });
        this.redirect();
      } catch (err) {
        this.error = true;
        this.loading = false;
      }
    },
    redirect() {
      if (this.$route.query.redirect) {
        this.$router.replace(this.$route.query.redirect);
      } else {
        this.$router.push('/app');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login__extra {
  font-size: 0.85rem;
  padding: 0 1.1rem;
  text-align: center;

  @include mq('desktop') {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.login__remember {
  font-size: 0.85rem;
}
.login__extra-link {
  color: #fff;
  opacity: 0.8;

  &:hover,
  &:focus {
    opacity: 1;
  }
}
</style>
