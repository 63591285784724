<template>
  <div>
    <button class="hamburger" @click="$store.dispatch('openMenu')">
      <span class="visuallyhidden">Otwórz menu</span>
      <svg
        class="hamburger__icon"
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="16"
        viewBox="0 0 23 16"
      >
        <g
          fill="none"
          fill-rule="evenodd"
          stroke="#0042FF"
          stroke-linecap="round"
          stroke-width="3"
        >
          <path d="M2 14h15.2M2 8h19M2 2h19" />
        </g>
      </svg>
    </button>
    <portal to="menu">
      <div :class="{ 'nav-open': $store.state.isMenuActive }">
        <button
          class="close-overlay"
          @click="$store.dispatch('closeMenu')"
          aria-hidden="true"
        ></button>
        <div
          @touchstart="onTouchStart"
          @touchmove="onTouchMove"
          @touchend="onTouchEnd"
          class="navigation-menu"
        >
          <router-link
            v-if="$route.name === 'home'"
            to="/"
            class="navigation-menu__logo mb-8"
          >
            <base-logo-vertical />
          </router-link>

          <ul v-if="$route.name === 'home'" class="navigation-menu__links">
            <li class="navigation-menu__element">
              <router-link to="#why-vigilex" class="navigation-menu__link"
                >dlaczego vigilex</router-link
              >
            </li>
            <li class="navigation-menu__element">
              <router-link to="/signup" class="navigation-menu__link"
                >załóż konto</router-link
              >
            </li>
            <li
              class="navigation-menu__element navigation-menu__element--login"
            >
              <router-link
                v-if="$store.getters['user/isLoggedIn']"
                :to="{ name: 'legislation' }"
                class="navigation-menu__link"
                >do serwisu</router-link
              >
              <router-link v-else to="/login" rel="nofollow" class="navigation-menu__link"
                >zaloguj się</router-link
              >
            </li>
            <li class="navigation-menu__element">
              <router-link to="#about-us" class="navigation-menu__link"
                >o nas</router-link
              >
            </li>
            <li class="navigation-menu__element">
              <router-link to="#contact" class="navigation-menu__link"
                >kontakt</router-link
              >
            </li>
          </ul>

          <ul v-else>
            <li
              v-if="!$store.getters['user/isLoggedIn']"
              class="navigation-menu__element"
            >
              <router-link
                :to="{ path: '/login', query: { redirect: $route.fullPath } }"
                class="navigation-menu__link"
                rel="nofollow"
              >
                zaloguj się
              </router-link>
            </li>
            <li
              v-for="(item, index) of mainMenu"
              :key="index"
              class="navigation-menu__element"
            >
              <button v-if="item.search" class="navigation-menu__link desktop">
                {{ item.name }}
              </button>
              <div
                v-else-if="item.mobileShowChildren"
                v-for="child in item.children"
                :key="child.name"
                class="navigation-menu__element"
              >
                <router-link :to="child.to" class="navigation-menu__link">
                  {{ child.name }}
                </router-link>
              </div>

              <button
                v-else-if="
                  item.modalSecondaryCondition &&
                    $store.getters[item.modalSecondaryCondition]
                "
                @click="
                  $store.commit('toggleGuestModalSecondary', true),
                    $store.dispatch('closeMenu')
                "
                class="navigation-menu__link"
              >
                {{ item.name }}
              </button>
              <router-link
                v-else-if="!item.private || $store.getters['user/isLoggedIn']"
                :to="item.to"
                class="navigation-menu__link"
              >
                {{ item.name }}
              </router-link>
              <button
                v-else
                @click="$store.commit('openGuestModal')"
                class="navigation-menu__link"
              >
                {{ item.name }}
              </button>
            </li>
            <li
              v-if="$store.getters['user/isLoggedIn']"
              class="navigation-menu__element"
            >
              <base-logout-link />
            </li>
          </ul>

          <base-button-exit
            class="navigation-menu__close-button"
            @click="$store.dispatch('closeMenu')"
          />
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mainMenu } from '@/router/mainMenu';

export default {
  data() {
    return {
      dragStartX: 0,
      dragCurrentX: 0,
      dragging: false,
    };
  },
  computed: {
    mainMenu() {
      return mainMenu(
        this.$store.getters['user/otherActs'],
        this.$store.getters['user/ue']
      );
    },
  },
  methods: {
    onTouchStart(evt) {
      if (!this.$store.state.isMenuActive) {
        return;
      }
      this.dragged = evt.currentTarget;
      this.dragStartX = evt.touches[0].pageX;
      this.dragCurrentX = this.dragStartX;
      this.dragging = true;

      requestAnimationFrame(this.update);
    },
    onTouchMove(evt) {
      if (!this.dragging) {
        return;
      }
      this.dragCurrentX = evt.touches[0].pageX;
    },
    onTouchEnd(evt) {
      if (!this.dragging) {
        return;
      }
      this.dragging = false;
      const translateX = Math.max(0, this.dragCurrentX - this.dragStartX);
      this.dragged.style.transform = '';

      if (translateX > 20) {
        this.$store.dispatch('closeMenu');
      }
    },
    update() {
      if (!this.dragging) {
        return;
      }
      requestAnimationFrame(this.update);
      const translateX = Math.max(0, this.dragCurrentX - this.dragStartX);
      this.dragged.style.transform = `translateX(${translateX}px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './NavigationMenu';
</style>
