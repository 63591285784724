<template>
  <div>
    <h2 class="login__form-title mb-12 " :class="{ 'mb-tablet-2': !formSent }">
      Nowe hasło
    </h2>

    <form v-if="!formSent" @submit.prevent="submit">
      <p class="login__form-info mb-12">
        Uzupełnij poniższe dane:
      </p>
      <base-input
        v-model="password"
        :error="error"
        name="password"
        type="password"
        required
        theme="light-blue"
        class="mb-8 mb-tablet-12"
        >nowe hasło</base-input
      >
      <base-input
        v-model="passwordRepeat"
        :error="error"
        name="passwordRepeat"
        type="password"
        required
        theme="light-blue"
        class="mb-8 mb-tablet-12"
        >powtórz nowe hasło</base-input
      >

      <base-button
        type="submit"
        theme="secondary"
        class="mb-4"
        :loading="loading"
        full
      >
        Dalej
      </base-button>
    </form>
    <div v-else class="mb-16">
      <p>Hasło zmienione!</p>
    </div>

    <div class="login__links">
      <router-link to="/login" rel="nofollow" type="button" class="login__links-item">
        Zaloguj się
      </router-link>
      <router-link to="/signup" class="login__links-item">
        Załóż konto
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      password: '',
      passwordRepeat: '',
      formSent: false,
      error: false,
    };
  },
  watch: {
    username() {
      this.removeError();
    },
    password() {
      this.removeError();
    },
  },
  methods: {
    removeError() {
      this.error = false;
    },
    async submit() {
      this.error = false;
      if (this.password !== this.passwordRepeat) {
        this.error = true;
        return;
      }
      this.loading = true;
      try {
        await this.$api.post('auth/users/reset_password_confirm/', {
          uid: this.$route.params.uid,
          token: this.$route.params.token,
          new_password: this.password,
          re_new_password: this.passwordRepeat,
        });
        this.formSent = true;
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
