<template>
  <section class="section-two mb-32 mb-desktop-64" id="section-two">
    <h2 class="heading color-white mb-24">Jak działa vigilex?</h2>

    <ul class="section-two__list row row--between">
      <li class="col-12 col-desktop-4 col-tablet-4 mb-16">
        <img
          src="../assets/icon-search-white.svg"
          class="section-two__icon mb-4"
          alt=""
        />
        <p class="heading heading--smaller mb-4">czyta</p>
        <p>projekty i dotyczące ich dokumenty</p>
      </li>

      <li class="col-12 col-desktop-4 col-tablet-4 mb-16">
        <img
          src="../assets/icon-repeat-white.svg"
          class="section-two__icon mb-4"
          alt=""
        />
        <p class="heading heading--smaller mb-4">porządkuje</p>
        <p>
          gromadzi i&nbsp;aktualizuje <br />
          dane z&nbsp;oficjalnych źródeł
        </p>
      </li>

      <li class="col-12 col-desktop-4 col-tablet-4">
        <img
          src="../assets/icon-collect-white.svg"
          class="section-two__icon mb-4"
          alt=""
        />
        <p class="heading heading--smaller mb-4">prezentuje</p>
        <p>kluczowe informacje o&nbsp;planowanych zmianach prawnych</p>
      </li>
    </ul>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section-two {
  color: #fff;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 5rem 3rem;
  padding-top: 40vh;
  border-radius: 1.42rem;
  text-align: center;
  background: $gradient-vertical;

  @include mq('phone') {
    margin-left: 0;
    margin-right: 0;
  }
  @include mq('tablet') {
    text-align: left;
    padding: 50px;
  }
  @include mq('desktop') {
    padding: 100px;
  }

  &__icon {
    width: 50px;
    height: 44px;

    @include mq('desktop') {
      width: 68px;
      height: 60px;
    }
  }
}
</style>
