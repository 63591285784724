<template>
  <nav class="navigation">
    <router-link
      v-if="$store.getters['user/isLoggedIn']"
      :to="{ name: 'news' }"
      class="navigation__login"
      >do serwisu</router-link
    >
    <router-link v-else to="/login" rel="nofollow" class="navigation__login"
      >zaloguj</router-link
    >
    <navigation-menu key="home-menu" />
  </nav>
</template>

<script>
import NavigationMenu from './NavigationMenu';

export default {
  components: {
    NavigationMenu,
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  display: flex;
  align-items: center;
  margin-top: -0.65rem;

  @include mq('tablet') {
    margin-top: -0.3rem;
  }

  &__login {
    display: none;

    @include mq('tablet') {
      display: inline;
      line-height: 1;
      font-size: 1.28rem;
      margin-top: -0.2em;
      margin-right: 2rem;
      color: $primary;
      transition: transform 0.15s $easeOutQuad;
      will-change: transform;
    }
  }
}
</style>
