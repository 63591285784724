<template>
  <div>
    <h2 class="login__form-title mb-12" :class="{ 'mb-tablet-2': !formSent }">
      Resetuj hasło
    </h2>

    <form v-if="!formSent" @submit.prevent="submit">
      <br>
      <base-input
        v-model="username"
        :error="error"
        name="username"
        required
        theme="light-blue"
        class="mb-8 mb-tablet-12"
        >adres e-mail</base-input
      >

      <base-button
        type="submit"
        theme="secondary"
        class="mb-4"
        :loading="loading"
        full
      >
        Dalej
      </base-button>
    </form>
    <div v-else class="mb-16">
      <p>
        Wysłane! Sprawdź swoją skrzynkę e-mail i naciśnij przycisk zamieszczony w
        naszej wiadomości, żeby ustawić nowe hasło.
      </p>
    </div>

    <div class="login__links">
      <router-link to="/login" rel="nofollow" type="button" class="login__links-item">
        Zaloguj się
      </router-link>
      <router-link to="/signup" class="login__links-item">
        Załóż konto
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      username: '',
      formSent: false,
      error: false,
    };
  },
  watch: {
    username() {
      this.removeError();
    },
    password() {
      this.removeError();
    },
  },
  methods: {
    removeError() {
      this.error = false;
    },
    async submit() {
      this.error = false;
      this.loading = true;
      try {
        await this.$api.post('auth/users/reset_password/', {
          email: this.username,
        });
        this.formSent = true;
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
