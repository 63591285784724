<template>
  <aside class="login">
    <div class="login__container">
      <div class="login__top">
        <router-link to="/" class="login__logo">
          <base-logo-vertical
            colorLogo="#fff"
            colorText="#fff"
            class="mobile"
          />
          <base-logo-horizontal class="tablet-desktop" />
        </router-link>

        <base-button-exit
          @click="$router.back()"
          class="login__back login__back--tablet"
        />
      </div>

      <div class="login__content">
        <div class="login__form">
          <login-password-reset v-if="resetPassword" />
          <login-password-confirm v-else-if="resetPasswordConfirm" />
          <login-form v-else />
        </div>

        <div class="login__image-container">
          <phone-component class="login__image" />
          <!-- <img
            class="login__image"
            src="@/assets/screenshots/phone.png"
            srcset="
              @/assets/screenshots/phone.png,
              @/assets/screenshots/phone-2x.png 2x
            "
            alt=""
            width="476"
            height="770"
          /> -->
        </div>
      </div>

      <base-button-exit
        type="light"
        @click="$router.back()"
        class="login__back login__back--mobile"
      />
    </div>
  </aside>
</template>

<script>
import LoginForm from './components/LoginForm';
import LoginPasswordReset from './components/LoginPasswordReset';
import LoginPasswordConfirm from './components/LoginPasswordConfirm';
import PhoneComponent from '../Home/components/PhoneComponent.vue';
import { router } from '@/router/router';

export default {
  components: {
    LoginForm,
    LoginPasswordReset,
    LoginPasswordConfirm,
    PhoneComponent,
  },
  metaInfo() {
    return {
      title: 'Zaloguj się',
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${router.currentRoute.path}`,
        },
      ],
    };
  },
  data() {
    return {
      resetPassword: false,
      resetPasswordConfirm: false,
    };
  },
  watch: {
    $route() {
      this.switchRoute();
    },
  },
  created() {
    this.switchRoute();
  },
  methods: {
    switchRoute() {
      this.resetPassword = this.$route.name === 'passwordReset';
      this.resetPasswordConfirm = this.$route.name === 'passwordResetConfirm';
    },
  },
};
</script>

<style lang="scss">
@import './Login';
</style>
