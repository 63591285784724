<template>
  <page class="home">
    <home-intro />
    <home-explain />
    <home-notifications />
    <home-in-place />
    <home-signup />
    <home-about />
    <home-contact />
  </page>
</template>

<script>
import Page from './components/Page';
import HomeIntro from './components/HomeIntro';
import HomeExplain from './components/HomeExplain';
import HomeNotifications from './components/HomeNotifications';
import HomeInPlace from './components/HomeInPlace';
import HomeSignup from './components/HomeSignup';
import HomeAbout from './components/HomeAbout';
import HomeContact from './components/HomeContact';

export default {
  name: 'home',
  components: {
    Page,
    HomeIntro,
    HomeExplain,
    HomeNotifications,
    HomeInPlace,
    HomeSignup,
    HomeAbout,
    HomeContact,
  },
  mounted() {
    this.$nextTick(() => {
      const anchor = this.$route.hash;
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor;
      }
    });
  },
};
</script>

<style lang="scss">
@import './Home';
</style>
